<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4">
        <Select
          v-model="query.assetId"
          placeholder="资产"
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.stationId"
          placeholder="站点"
          :clearable="true"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.position"
          placeholder="位置"
          :clearable="true"
        >
          <Option
            v-for="item in positionArray"
            :key="'position_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.resourcetypeId"
          :clearable="true"
          placeholder="资源类型"
        >
        <OptionGroup
            v-for="item in resourceTypeArray"
            :key="'re_parent_'+item.id"
            :label="item.name"
          >
            <Option
              v-for="child in item.children"
              :value="child.id"
              :key="'re_child'+child.id"
            >{{ child.name }}</Option>
          </OptionGroup>
        </Select>
      </i-col>
      <i-col span="6">
        <Input
          search
          v-model="query.wordsKey"
          enter-button="搜索"
          placeholder="资源编号、站点名称"
          @on-search="handleSearch"
        />
      </i-col>
      <i-col span="2">
        <Button
          type="primary"
          :disabled="selectedResourceIdArray.length===0"
          @click="handleCreateTask"
        >创建任务</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        :data="tableData.list"
        :columns="tableColumns"
        @on-selection-change="handleSelection"
      ></Table>
      <div class="paging_style">
        <Page size="small"
          :total="tableData.totalRow"
          :page-size="query.pageSize"
          show-total
          show-elevator
          show-sizer
          :page-size-opts="pagesizeSetting"
          @on-page-size-change='changePageSize'
          :current="query.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { companyMixin } from '@/assets/mixins/company'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPositions } from '@/api/product/resource'
import { getResourceTypeList } from '@/api/product/asset'
export default {
  mixins: [companyMixin],
  data () {
    return {
      query: {
        assetId: null,
        stationId: null,
        position: null,
        resourcetypeId: null,
        status: null,
        wordsKey: '',
        pageNumber: 1,
        pageSize: 15
      },
      pagesizeSetting: [15, 50, 100, 200, 500],
      companyId: this.$store.getters.token.userInfo.companyId,
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '资源编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '资源类型', align: 'center', key: 'resourcetypeName' }
      ]
    }
  },
  created () {
    this.getCompanyAssetArray()
  },
  destroyed () {
    this.selectedResourceIdArray = []
  },
  computed: {
    tableData () {
      return this.$store.state.workOrder.newTaskResourceData
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.workOrder.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    }
  },
  methods: {
    getTableData () {
      this.$store.dispatch('getNewTaskResourceData', this.query)
    },
    handleChangeAsset () {
      this.query.pageNumber = 1
      this.$store.commit('set_selected_assetId', this.query.assetId)
      this.getStationData()
      this.getPositionData()
      this.getResourceTypeData()
      this.getTableData()
    },
    getStationData () {
      getRimStationByAssetIds({ assetIds: JSON.stringify([this.query.assetId]) }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      const assetIds = []
      assetIds.push(this.query.assetId)
      const data = {
        assetIds: JSON.stringify(assetIds)
      }
      getPositions(data).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    getResourceTypeData () {
      const postData = {
        assetId: this.query.assetId
      }
      getResourceTypeList(postData).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.getTableData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    handleSelection (selection) {
      this.selectedResourceIdArray = selection.map(x => x.id)
    },
    handleCreateTask () {
      this.$store.commit('set_left_component', 'SetNewTask')
      this.$store.commit('set_show_left_block', true)
    }
  },
  watch: {
    companyAssetArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.length) {
          this.query.assetId = val[0].id
          this.$store.commit('set_selected_assetId', this.query.assetId)
          this.getStationData()
          this.getPositionData()
          this.getResourceTypeData()
          this.getTableData()
        }
      }
    }
  }
}
</script>
